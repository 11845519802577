@font-face {
  font-family: 'DINOT-Regular';
  font-style: normal;
  src: local('DINOT-Regular'),
       url('./fonts/DINOT-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Gidole-Regular';
  font-style: normal;
  src: local('Gidole-Regular'),
       url('./fonts/Gidole-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  src: local('Oswald'),
       url('./fonts/Oswald.ttf') format('truetype');
}

.dzu-dropzone {
  border-radius: 0px !important;
  /* background: #282828; */
  border: 2px solid black;
}

div.dzu-dropzone progress {
  color: red;
}

div.dzu-dropzone progress::-webkit-progress-value {
  background: red;
}

div.dzu-dropzone progress::-moz-progress-bar {
  background: red;
}

.dzu-inputLabel {
  color: #e53e3e;
  font-family: 'DINOT-Regular';
  font-size: 1.2em;
  text-transform: uppercase;
}

.dzu-previewFileName {
  /* color: white; */
}
